 import { useState, useEffect } from 'react';
import { ConfigProvider, Typography, Button, Flex, FloatButton, Form, Input, Card, InputNumber, Switch, DatePicker, TimePicker, notification } from 'antd';
import { PhoneFilled, MailOutlined, BankFilled } from '@ant-design/icons';
import WhatsAppButton from './resources/WhatsAppButtonGreenMedium.svg';
import whatsAppIcon from './resources/whatsappIconWhite.svg';
import bgVideo from './resources/bgVideo.mp4';
import headerBorder from './resources/headerBorder.png';
import nameEn from './resources/nameEn.svg';
import nameIt from './resources/nameIt.svg';
import car from './resources/carTBg.png';

import img1 from './resources/img1.jpg';
import img3 from './resources/img3.jpg';

import img4 from './resources/img4.png';
import img6 from './resources/img6.png';


import carBg from './resources/yellowBlob.svg';
import italyFlag from './resources/italy.png';
import ukFlag from './resources/uk.png';
import text from './text.json';
import { PHONE, EMAIL, FIRM } from './Constants';
import { getLanguage, dateTimeToTimestamp } from './Utils';
import './App.css';


const formKeys = [
    "name",
    "surname",
    "phoneNumber",
    "email",
    "departureDate",
    "returnDate",
    "departureLocation",
    "destinationLocation",
    "departureTime",
    "numberOfPassengers",
    "arrivalTime",
    "roundTrip"
];

const textInputKeys = ["name", "surname", "departureLocation", "destinationLocation"];

const ln = getLanguage();

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const call = () => {
    window.open(`tel:${PHONE}`);
  }

  const chatOnWhatsApp = () => {
    window.open(`https://wa.me/${PHONE.replace('+', '')}`);
  }

  const email = () => {
    window.open(`mailto:${EMAIL}`);
  }

  const showError = () => {
    notification.error({
      placement: 'topLeft',
      duration: 20,
      top: 100,
      message: text[ln].errorTitle,
      description: text[ln].errorDescription
    });
  }

  const showSuccess = () => {
    notification.success({
      placement: 'topLeft',
      duration: 20,
      top: 100,
      message: text[ln].successTitle,
      description: text[ln].successDescription
    });
  }

  window.showError = showError;
  window.showSuccess = showSuccess;

  const formSumbit = async (data) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const token = await window.grecaptcha.execute({ action: 'submit' });

       const body = {
        token,
        departureDate: dateTimeToTimestamp(data.departureDate, data.departureTime),
        returnDate: dateTimeToTimestamp(data.returnDate, data.arrivalTime),
        departureLocation: data.departureLocation,
        destinationLocation: data.destinationLocation,
        email: data.email,
        message: data.message,
        name: data.name,
        numberOfPassengers: data.numberOfPassengers,
        phoneNumber: data.phoneNumber,
        roundTrip: !!data.roundTrip,
        surname: data.surname
      }

      const result = await fetch('/sendEmail', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const json = await result.json();

      if (json && json.success) {
        form.resetFields();
        showSuccess();
      } else {
        showError();
      }
    } catch(e) {
      console.warn(e);
      showError();
    }
    setIsLoading(false);

  }

  useEffect(() => {
    const otherLn = getLanguage() === 'en' ? 'it' : 'en';
    document.querySelector('link.languageLink')?.remove();
    const link = document.createElement('link');
    link.classList.add('languageLink');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('hreflang', otherLn);
    link.setAttribute('href', `https://eduardautoservizi.com/${otherLn}`);
    document.head.append(link);

    const query = '(min-width: 1200px)';
    const media = window.matchMedia(query);
    const listener = () => {
      setIsSmallScreen(!media.matches);
    };
    listener(media);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, []);

  return (
    <div className="App">
      <video id="bgVideo" autoPlay muted loop src={bgVideo} />
      <CustomTheme>
        <Flex className="languageBtns">
          <a href="/it"><Button type="text" size="large" icon={<img width="16" height="16" src={italyFlag} alt="it"/>}>Italian</Button></a>
          <a href="/en"><Button type="text" size="large" icon={<img width="16" height="16" src={ukFlag} alt="en" />}>English</Button></a>
        </Flex>
      </CustomTheme>
      <Flex vertical align="center" className="mainContainer">
        <Flex justify="center" className="logoImgWrapper">
          <img className="logoImg" src={ln === 'it' ? nameIt : nameEn} alt="logo" />
        </Flex>
        <Flex gap={isSmallScreen ? 'small' : 'large'} vertical={isSmallScreen} className="carAndDescription">
          <div className="carImgWrapper">
            <img className="carBackground" src={carBg} alt="" />
            <img className="carImg" src={car} alt="car"/>
          </div>
          <CustomTheme>
            <Flex vertical className="description">
              <Typography.Title level={3}>
                {text[ln].description1}
              </Typography.Title>
              <img className="headerBorder" src={headerBorder} alt=""/>
              <Typography.Title level={5}>
                {text[ln].description2}
              </Typography.Title>
              <Typography.Title level={5}>
                {text[ln].description3}
              </Typography.Title>
              <Typography.Title level={5}>
                {text[ln].description4}
              </Typography.Title>
              <Typography.Title level={5}>
                {text[ln].description5}
              </Typography.Title>
            </Flex>
          </CustomTheme>
        </Flex>
        <Flex vertical={isSmallScreen} justify="space-between" align="center" className="contactAndReseWrapper">
          <Flex vertical align="center" justify="center">
            <Flex vertical className="contactsWrapper" gap="large" align="center" justify="center">
              <Button type="primary" icon={<PhoneFilled />} onClick={call}>{PHONE}</Button>
              <img className="whatsAppBtn" src={WhatsAppButton} alt="Chat on WhatsApp" onClick={chatOnWhatsApp} />
              <Button type="primary" danger icon={<MailOutlined />} onClick={email}>{EMAIL}</Button>
            </Flex>
            <Flex className="imgWrapper" align="center" justify="center">
              <div className="imgContainer">
                <img id="blob1" className="img" src={carBg} alt="7" />
                <img id="blob2" className="img" src={carBg} alt="8" />
                <img id="img1" className="img" src={img1} alt="1" />
                <img id="img3" className="img" src={img3} alt="3" />
                <img id="img4" className="img" src={img4} alt="4" />
                <img id="img6" className="img" src={img6} alt="6" />
                
              </div>
            </Flex>
          </Flex>
          <Flex className="cardWrapper">
            <Card className="formCard" title={text[ln].formName}>
              <Form
                form={form}
                layout="vertical"
                name="mainForm"
                initialValues={{ remember: true }}
                onFinish={formSumbit}
              >
                <Flex wrap="wrap" justify="space-between">
                    {formKeys.map((objKey) => (<Flex key={objKey} style={{ width: '48%', boxSizing: 'border-box' }}><FormItem objKey={objKey} ln={ln} /></Flex>))}
                </Flex>
                <Form.Item
                  key="message"
                  label={text[ln].message}
                  name="message"
                >
                  <Input.TextArea />
                </Form.Item>
                <Flex justify="center">
                  <div
                    ref={(node) => { try{ window.grecaptcha.render(node) } catch(e) {} } }
                    id="recaptchaContainer"
                    className="g-recaptcha"
                    data-sitekey="6Lf48IIpAAAAAFVZ7hkm56n3MkLXGoTRI1fHel9q"
                    data-badge="inline"
                    data-size="invisible"
                  >
                  </div>
                </Flex>
                <Flex justify="center">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>{text[ln].formSubmit}</Button>
                  </Form.Item>
                </Flex>
              </Form>
            </Card>
          </Flex>
        </Flex>
      </Flex>
      <CustomTheme>
        <FloatButton.Group shape="circle" style={{ right: 24 }}>
          <FloatButton type="primary" style={{ filter: 'hue-rotate(60deg)' }} icon={<PhoneFilled />} tooltip={<div>Call</div>}  onClick={call} />
          <FloatButton type="primary" icon={<img width="18" src={whatsAppIcon} alt="WhatsApp Icon"/>} tooltip={<div>Chat on Whatsapp</div>} onClick={chatOnWhatsApp} />
        </FloatButton.Group>
          <div className="firmNrWrapper">
            <BankFilled />
            <span className="firmNr">{text[ln].vat} {FIRM}</span>
          </div>
      </CustomTheme>
    </div>
  );
}


function CustomTheme({ children }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextBase: '#ffffff',
          colorBgBase: '#000000',
          colorPrimary: '#25d366',
          colorBgContainer: '#ffffff',
          colorBorderSecondary: '#f0f0f0',
          colorBorder: '#d9d9d9',
        },
        components: {
          Button: {
            colorText: '#FFFFFF',
          },
          Tooltip: {
            colorTextLightSolid: 'black'
          },
        }
      }}
    >
      {children}
    </ConfigProvider>
  );
}

function FormItem({ objKey, ln }) {
  const errorKey = `${objKey}Error`;
  let component;
  
  if (textInputKeys.includes(objKey)) {
    component = <Input />
  } else if (objKey === 'numberOfPassengers') {
    component = <InputNumber style={{ width: '100%' }} />
  } else if (objKey === 'roundTrip') {
    component = <Switch style={{  }} />
  } else if (['departureDate', 'returnDate'].includes(objKey)) {
    component = <DatePicker placeholder="" style={{ width: '100%' }} />
  } else if (['departureTime', 'arrivalTime'].includes(objKey)) {
    component = <TimePicker placeholder="" format="HH:mm" style={{ width: '100%' }} />
  } else if (objKey === 'phoneNumber') {
    component = <Input type="tel" />
  } else if (objKey === 'email') {
    component =  <Input type="mail" />
  }
  return (
    <Form.Item
      label={text[ln][objKey]}
      name={objKey}
      rules={[{ required: !!text[ln][errorKey], message: text[ln][errorKey] }]}
      style={{ width: '100%', alignSelf: 'flex-end' }}
    >
      {component}
    </Form.Item>
  );
}

export default App;
