export const getLanguage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get('language')) {
    if (searchParams.get('language').toLowerCase() === 'italian') {
      localStorage.ln = 'it';
      return 'it';
    } else if (searchParams.get('language').toLowerCase() === 'english') {
      localStorage.ln = 'en';
      return 'en';
    }
  }

  if (window.location.pathname) {
    if (window.location.pathname === '/it') {
      localStorage.ln = 'it';
      return 'it';
    } else if (window.location.pathname === '/en') {
      localStorage.ln = 'en';
      return 'en';
    }
  }

  if (localStorage.ln && ['it', 'en'].includes(localStorage.ln)) {
    return localStorage.ln
  }

  if ((navigator.language || navigator.userLanguage) && (navigator.language || navigator.userLanguage).toLowerCase().startsWith('it')) {
    return 'it';
  }

  return 'en';
}

export function dateTimeToTimestamp(date, time) {
  const d = date.toDate();
  if (time) {
    d.setHours(time.hour(), time.minute());
  }
  return d.getTime();
}

export function getDateInItallyStrRo(date) {
  const options = { timeZone: 'Europe/Rome', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeInItaly = date.toLocaleString('ro-RO', options);

  return dateTimeInItaly;
}
